const toggle = (e) => {
  e.preventDefault();
  e.stopPropagation();

  const overlay = document.querySelector(".gk-nav-mobile-overlay");

  if (overlay.classList.contains("gk-is-visible")) {
    document.body.classList.remove("gk-no-scroll");
    overlay.classList.remove("gk-is-visible");
  } else {
    document.body.classList.add("gk-no-scroll");
    overlay.classList.add("gk-is-visible");
  }
};

const overlayClick = (e) => {
  if (e.target.classList.contains("gk-nav-mobile-overlay")) {
    toggle(e);
  }
};

const attach = () => {
  document
    .querySelector(".gk-nav-mobile-toggle a")
    .addEventListener("click", toggle);

  document
    .querySelector(".gk-nav-mobile-close a")
    .addEventListener("click", toggle);

  document
    .querySelector(".gk-nav-mobile-overlay")
    .addEventListener("click", overlayClick);
};

document.addEventListener("turbolinks:load", attach);
